import { useEffect, useState } from "react";
import { Notificacao } from "../../util/notificacao";
import { API } from "../../../conexao/api";
import { ApagaLocal, GravaLocal } from "../../../conexao/localstorage";
import {
  PrimeiraLetraMaiuscula,
  formatarNumeroCelular,
} from "../../util/primeiraLetraMaiuscula";
import { BuscaLocal } from "../../../conexao/localstorageBusca";
import { formatoBrasileiro } from "../../util/formatacaoMoeda";

export function PageMotoboy() {
  const [Tipo, setTipo] = useState(2);
  const [MotoboyLogado, setMotoboyLogado] = useState(false);
  const [DadosMotoboy, setDadosMotoboy] = useState({});
  const [WarningMotoboy, setWarningMotoboy] = useState(false);
  const [Empresa, setDadosEmpresa] = useState({});

  const [inputName, setInputName] = useState("");
  const [inpuPedido, setInpuPedido] = useState("");

  const [DadosPedidoSelecionado, setDadosPedidoSelecionado] = useState(null);
  const [Pedidos, setPedidos] = useState([]);

  const [dataAtual, setDataAtual] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const dataAnterior = new Date();
  dataAnterior.setDate(dataAnterior.getDate() - 7);
  const [dataSeteDiasAtras, setDataSeteDiasAtras] = useState(
    dataAnterior.toISOString().slice(0, 10)
  );

  function agruparPorData(array) {
    const map = {};

    array.forEach((item) => {
      // Se a data não existe no mapa, inicialize ela
      if (!map[item.data]) {
        map[item.data] = {
          data: item.data,
          total: 0,
          tax: 0,
          pedidos: [],
          pagamento: [],
        };
      }

      // Adicione os valores
      map[item.data].total += parseFloat(item.total);
      map[item.data].tax += parseFloat(item.tax);
      map[item.data].pedidos = [
        ...new Set(map[item.data].pedidos.concat(item.pedidos.split(","))),
      ];
      map[item.data].pagamento.push({
        name: item.forma_pagamento,
        total: parseFloat(item.total),
      });
    });

    // Transforma o mapa em um array de objetos
    return Object.values(map).map((item) => ({
      ...item,
      pedidos: item.pedidos.join(","),
    }));
  }

  function DadosEmpresa() {
    // //console.log(dataAnterior)
    let empresaLogada = process.env.REACT_APP_EMPRESA
      ? process.env.REACT_APP_EMPRESA
      : "Demo";
    const link =
      "generica.php?tabela=ws_empresa&where=nome_empresa_link='" +
      empresaLogada +
      "'";
    console.log(link);

    API.get(link).then(function (response) {
      // GravaLocal('dados_empresa', JSON.stringify(response.data));
      setDadosEmpresa(response.data[0]);
      BuscaPedidos();
    });
  }

  function BuscaPedidos() {
    if (!DadosMotoboy.id) {
      return false;
    }
    console.log(
      `motoboy-pedidos.php?codigo=${DadosMotoboy.id}&ini=${dataSeteDiasAtras}&fim=${dataAtual}`
    );
    API.get(
      `motoboy-pedidos.php?codigo=${DadosMotoboy.id}&ini=${dataSeteDiasAtras}&fim=${dataAtual}`
    ).then(function (response) {
      // GravaLocal('dados_empresa', JSON.stringify(response.data));
      // //console.log(agruparPorData(response.data));
      setPedidos(agruparPorData(response.data));
      // setDadosEmpresa(response.data[0]);
    });
  }

  function UserId() {
    return Empresa.user_id;
  }

  function BuscaMotoboy() {
    setWarningMotoboy(false);
    const link =
      "generica.php?tabela=ws_motoboys&where=user_id=" +
      UserId() +
      " and upper(deliveryman_name)='" +
      inputName.toLocaleUpperCase() +
      "'";
    // //console.log(link);
    API.get(link).then(function (response) {
      if (response.data) {
        GravaLocal(UserId() + "_motoboy", JSON.stringify(response.data[0]));
        setDadosMotoboy(response.data[0]);
        setMotoboyLogado(true);
      } else {
        setWarningMotoboy(true);
      }
    });
  }

  useEffect(() => {
    DadosEmpresa();
  }, []);

  useEffect(() => {
    var DadosM = JSON.parse(BuscaLocal(UserId() + "_motoboy"));

    if (DadosM != undefined) {
      setDadosMotoboy(DadosM);
      setMotoboyLogado(true);
    }
  }, [Empresa]);

  function EnviarAlteracao() {
    let Body = {
      pedido: DadosPedidoSelecionado.id,
      motoboy: DadosMotoboy.id,
      celular: "55" + DadosMotoboy.deliveryman_phone_number + "@c.us",
      dia: DadosPedidoSelecionado.codigo_pedido,
      type: Tipo,
      user: UserId(),
      latitude: parseFloat(DadosPedidoSelecionado.lat),
      longitude: parseFloat(DadosPedidoSelecionado.lgn),
      endereco:
        DadosPedidoSelecionado.rua +
        ", Nº " +
        DadosPedidoSelecionado.unidade +
        " - " +
        DadosPedidoSelecionado.bairro +
        "/" +
        DadosPedidoSelecionado.cidade,
    };

    API.post("motoboy-post.php", Body).then(function (response) {
      Notificacao("sucesso", "Pedido atribuido com sucesso!");
      BuscaPedidos();
    });

    setInpuPedido("");
    setDadosPedidoSelecionado(null);
  }

  function formataData(data) {
    let partes = data.split("-");

    if (
      partes.length !== 3 ||
      partes[0].length !== 4 ||
      partes[1].length !== 2 ||
      partes[2].length !== 2
    ) {
      throw new Error("Formato de data inválido");
    }

    return `${partes[2]}/${partes[1]}/2023`;
  }

  function ConsultarPedido() {
    API.get(
      "motoboy-busca.php?codigo=" + inpuPedido + "&user=" + UserId()
    ).then(function (response) {
      console.log(response.data[0]);
      console.log(
        "motoboy-busca.php?codigo=" + inpuPedido + "&user=" + UserId()
      );
      if (!response.data) {
        Notificacao("erro", "Não existe pedido com o código informado!");
      } else {
        setDadosPedidoSelecionado(response.data[0]);
      }
    });
  }
  useEffect(() => {
    if (DadosMotoboy.id) {
      BuscaPedidos();
    }
  }, [DadosMotoboy, dataAtual, dataSeteDiasAtras]);

  function Sair() {
    ApagaLocal(UserId() + "_motoboy");
    setDadosMotoboy({});
    setMotoboyLogado(false);
  }

  return (
    <>
      <div style={{ padding: "10px" }}>
        {MotoboyLogado ? (
          <>
            <div
              style={{
                display: "flex",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                alignItems: "center",
                backgroundColor: "#f9f9f9",
              }}
            >
              <img
                src={`https://fotos.goopedir.com/fotos/empresa/${btoa(
                  UserId()
                )}`}
                alt=""
                style={{
                  height: "75px",
                  borderRadius: "50%",
                  border: "2px solid #ddd",
                  marginRight: "15px",
                }}
              />
              <div>
                <h1 style={{ fontSize: "1.2rem", margin: "0 0 5px 0" }}>
                  {Empresa.nome_empresa}
                </h1>
                <p style={{ margin: 0 }}>
                  Olá,{" "}
                  <b>
                    {DadosMotoboy.id +
                      " - " +
                      PrimeiraLetraMaiuscula(DadosMotoboy?.deliveryman_name)}
                  </b>
                </p>
              </div>
            </div>

            <h3
              style={{ marginTop: "20px", fontSize: "1.5rem", color: "#333" }}
            >
              Consultar Pedido
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "15px",
                gap: "10px",
                backgroundColor: "#fff",
              }}
            >
              <label style={{ marginBottom: "5px", fontWeight: "bold" }}>
                Código do Pedido
              </label>
              <input
                type="number"
                onChange={(e) => setInpuPedido(e.target.value)}
                value={inpuPedido}
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                }}
              />
              <button
                style={{
                  height: "50px",
                  backgroundColor: "#007BFF",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={ConsultarPedido}
              >
                Consultar
              </button>

              {DadosPedidoSelecionado ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <button
                      onClick={() => setTipo(1)}
                      style={{
                        flex: 1,
                        height: "50px",
                        backgroundColor: Tipo == 1 ? "#007BFF" : "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        color: Tipo == 1 ? "#fff" : "#333",
                        cursor: "pointer",
                      }}
                    >
                      Saiu Entrega
                    </button>
                    <button
                      onClick={() => setTipo(2)}
                      style={{
                        flex: 1,
                        height: "50px",
                        backgroundColor: Tipo == 2 ? "#007BFF" : "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        color: Tipo == 2 ? "#fff" : "#333",
                        cursor: "pointer",
                      }}
                    >
                      Entregue
                    </button>
                  </div>
                  <button
                    style={{
                      height: "50px",
                      backgroundColor: "#28a745",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                    onClick={EnviarAlteracao}
                  >
                    Enviar
                  </button>
                </>
              ) : null}
            </div>

            {DadosPedidoSelecionado ? (
              <div
                style={{
                  border: "1px solid #ddd",
                  padding: "15px",
                  borderRadius: "8px",
                  marginTop: "20px",
                  backgroundColor: "#fff",
                }}
              >
                <p style={{ color: "#666", margin: 0 }}>
                  #{DadosPedidoSelecionado.id}
                </p>
                <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  {DadosPedidoSelecionado.codigo_pedido}
                </p>
                <p style={{ color: "#666", margin: "10px 0 0 0" }}>Data</p>
                <p style={{ fontWeight: "bold" }}>
                  {DadosPedidoSelecionado.data_chart
                    ? formataData(DadosPedidoSelecionado.data_chart2)
                    : ""}
                </p>
                <p style={{ color: "#666", margin: "10px 0 0 0" }}>Cliente</p>
                <p style={{ fontWeight: "bold" }}>
                  {formatarNumeroCelular(DadosPedidoSelecionado.telefone) +
                    " " +
                    DadosPedidoSelecionado.nome}
                </p>
                <p style={{ color: "#666", margin: "10px 0 0 0" }}>Endereço</p>
                <p style={{ fontWeight: "bold" }}>
                  {DadosPedidoSelecionado.rua +
                    ", Nº " +
                    DadosPedidoSelecionado.unidade +
                    " - " +
                    DadosPedidoSelecionado.bairro +
                    "/" +
                    DadosPedidoSelecionado.cidade}
                </p>
                <p style={{ color: "#666", margin: "10px 0 0 0" }}>Taxa</p>
                <p style={{ fontWeight: "bold" }}>
                  {formatoBrasileiro.format(DadosPedidoSelecionado.valor_taxa)}
                </p>
                <p style={{ color: "#666", margin: "10px 0 0 0" }}>Total</p>
                <p style={{ fontWeight: "bold" }}>
                  {formatoBrasileiro.format(DadosPedidoSelecionado.total)}
                </p>
                <p style={{ color: "#666", margin: "10px 0 0 0" }}>Pagamento</p>
                <p style={{ fontWeight: "bold" }}>
                  {DadosPedidoSelecionado.forma_pagamento}
                </p>
                <p style={{ color: "#666", margin: "10px 0 0 0" }}>Troco</p>
                <p style={{ fontWeight: "bold" }}>
                  {formatoBrasileiro.format(DadosPedidoSelecionado.valor_troco)}
                </p>
              </div>
            ) : null}

            <div style={{ marginTop: "20px" }}>
              <div>
                <h3 style={{ fontSize: "1.5rem", color: "#333" }}>
                  Suas Entregas no período
                </h3>
              </div>
              <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
                <input
                  type="date"
                  value={dataSeteDiasAtras}
                  style={{
                    flex: 1,
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "50px",
                  }}
                />
                <input
                  type="date"
                  value={dataAtual}
                  style={{
                    flex: 1,
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "50px",
                  }}
                />
              </div>
            </div>

            <div>
              {Pedidos.map((item, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "15px",
                    marginBottom: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <p style={{ color: "#666", margin: 0 }}>Data</p>
                  <p style={{ fontWeight: "bold" }}>{formataData(item.data)}</p>
                  <p style={{ color: "#666", margin: "10px 0 0 0" }}>Pedidos</p>
                  <p style={{ fontWeight: "bold" }}>{item.pedidos}</p>
                  {item.pagamento.map((pagamento, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "5px",
                        borderBottom: "1px solid #eee",
                        marginBottom: "5px",
                      }}
                    >
                      <p style={{ margin: 0 }}>{pagamento.name}</p>
                      <p style={{ fontWeight: "bold", margin: 0 }}>
                        {formatoBrasileiro.format(pagamento.total)}
                      </p>
                    </div>
                  ))}
                  <p style={{ color: "#666", margin: "10px 0 0 0" }}>Taxa</p>
                  <p style={{ fontWeight: "bold" }}>
                    {formatoBrasileiro.format(item.tax)}
                  </p>
                  <p style={{ color: "#666", margin: "10px 0 0 0" }}>Total</p>
                  <p style={{ fontWeight: "bold" }}>
                    {formatoBrasileiro.format(item.total)}
                  </p>
                </div>
              ))}
            </div>
            <button
              onClick={Sair}
              style={{
                backgroundColor: "#dc3545", // Red color
                color: "#fff",
                width: "100%",
                height: "50px",
                borderRadius: "8px",
                border: "none",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#c82333")} // Darker red on hover
              onMouseOut={(e) => (e.target.style.backgroundColor = "#dc3545")} // Original red on mouse out
            >
              Sair
            </button>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                height: "97vh",
                width: "99vw",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f4f4f9", // Light background color for contrast
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  maxWidth: "400px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  padding: "30px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    src="../motoboy.png"
                    alt="motoboy"
                    style={{
                      height: "60px",
                      width: "60px",
                      border: "2px solid #ddd",
                      borderRadius: "50%",
                      marginBottom: "10px",
                    }}
                  />
                  <h3 style={{ margin: 0, color: "#333" }}>Login Motoboy</h3>
                </div>
                <label
                  style={{
                    marginBottom: "5px",
                    fontWeight: "bold",
                    color: "#555",
                  }}
                >
                  Motoboy
                </label>
                <input
                  type="text"
                  placeholder="Nome"
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    height: "50px",
                    marginBottom: "10px",
                    padding: "0 10px",
                    fontSize: "16px",
                  }}
                  onChange={(e) => setInputName(e.target.value)}
                  value={inputName}
                />
                {WarningMotoboy && (
                  <span
                    style={{
                      color: "red",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Motoboy não cadastrado!
                  </span>
                )}
                <button
                  onClick={BuscaMotoboy}
                  style={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: "#007BFF",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#0056b3")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "#007BFF")
                  }
                >
                  Acessar
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
